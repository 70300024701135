import React from 'react';
import { Helmet } from 'react-helmet';
import socialImage from '../../images/social-image.jpg';

const metaTitle = 'Poetry by Andrew';
const metaDescription =
  'I let my heart bleed on my pen so that I can share with you the words that come from my soul.';
const BASE_URL = 'https://poetrybyandrew.com';
const socialImageUrl = BASE_URL + socialImage;

function Meta() {
  return (
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/vmc5emz.css" />
      <title>{metaTitle}</title>
      <meta name="og:title" content={metaTitle} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta name="og:description" content={metaDescription} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="og:image" content={socialImageUrl} />
      <meta name="og:image:secure_url" content={socialImageUrl} />
      <meta name="twitter:image" content={socialImageUrl} />
      <meta name="twitter:card" content="summary_large_card" />
    </Helmet>
  );
}

export default Meta;
