import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

export const Header = styled.header(({ theme, menuActive }) => {
  return css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: ${theme.headerHeight}px;
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;
    border-bottom: 1px solid ${theme.color.dark};
    background-color: ${theme.color.white};
    transform: ${menuActive
      ? `translateX(-${theme.sidebarWidth}px)`
      : `translateX(0)`};
    transition: 0.3s transform ease-in-out;
    z-index: 1000;

    ${theme.mediaUp.lg`
      display: none;
    `}
  `;
});

export const Link = styled(GatsbyLink)`
  display: inline-block;

  &:hover {
    text-decoration: none;
  }
`;

export const Logo = styled.img`
  height: 30px;
  width: auto;
`;

export const Toggle = styled.button`
  padding: 0.25rem;
  border: 0;
  margin-left: auto;
  background: none;

  &:focus {
    outline: none;
  }
`;

export const Icon = styled.img`
  width: 25px;
  height: auto;
`;
