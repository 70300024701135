import React from 'react';
import { Footer as StyledFooter, Copy } from './styled';

const year = new Date().getFullYear();
const copy = `&copy; ${year} Andrew Messner &middot; <a href="https://arteagabriel.io" target="_blank" rel="noopener noreferrer">Made with ❤️</a>`;

function Footer() {
  return (
    <StyledFooter>
      <Copy dangerouslySetInnerHTML={{ __html: copy }} />
    </StyledFooter>
  );
}

export default Footer;
