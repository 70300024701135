import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../style/globalStyle';
import theme from '../../style/theme';
import { Layout as StyledLayout, Body } from './styled';
import Meta from '../Meta';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

function Layout({ children }) {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <>
      <Meta />

      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <Header setMenuActive={setMenuActive} menuActive={menuActive} />
        <Sidebar setMenuActive={setMenuActive} menuActive={menuActive} />

        <StyledLayout menuActive={menuActive}>
          <Body>
            {children}

            <Footer />
          </Body>
        </StyledLayout>
      </ThemeProvider>
    </>
  );
}

export default Layout;
