import { css } from 'styled-components';

const breakpoints = {
  xl: 1200,
  lg: 1024,
  md: 768,
  sm: 576,
  xs: 375,
};

const mediaUp = Object.keys(breakpoints).reduce((accumulator, label) => {
  const emSize = breakpoints[label] / 16;

  return {
    ...accumulator,
    [label]: (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)}
      }
    `,
  };
}, {});

const mediaDown = Object.keys(breakpoints).reduce((accumulator, label) => {
  const emSize = (breakpoints[label] - 0.2) / 16;

  return {
    ...accumulator,
    [label]: (...args) => css`
      @media (max-width: ${emSize}em) {
        ${css(...args)}
      }
    `,
  };
}, {});

const theme = {
  color: {
    white: '#fff',
    dark: '#121212',
    primary: '#F03009',
  },
  font: {
    family: {
      sansSerif: 'proxima-nova, Roboto, Helvetica, Arial, sans-serif',
      serif: 'garamond-premier-pro, Georgia, Times, sans-serif',
      monospace:
        'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    },
    size: {
      base: '1rem',
    },
    weight: {
      normal: 400,
      bold: 700,
      bolder: 700,
      base: 400,
    },
    lineHeight: 1.5,
  },
  mediaUp,
  mediaDown,
  headerHeight: 50,
  headerHeightLg: 80,
  sidebarWidth: 300,
};

export default theme;
