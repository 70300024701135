function getPoemSlug(date, slug) {
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const slugYear = date.getFullYear();
  const slugDay = day.length === 1 ? '0' + day : day;
  const slugMonth = month.length === 1 ? '0' + month : month;

  return `/${slugYear}/${slugMonth}/${slugDay}/${slug}`;
}

module.exports.getPoemSlug = getPoemSlug;
