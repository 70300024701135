import styled from 'styled-components';
import { rem } from 'polished';

export const Footer = styled.footer`
  padding: 2rem;

  ${props => props.theme.mediaUp.lg`
    padding: 3rem 5rem;
  `}
`;

export const Copy = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  font-weight: ${props => props.theme.font.weight.bold};
  text-transform: uppercase;
  letter-spacing: 1px;
`;
