import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { lighten } from 'polished';

export const Nav = styled.nav(
  () =>
    css`
      position: relative;
    `
);

export const Link = styled(GatsbyLink)(
  ({ theme }) => css`
    position: relative;
    display: block;
    z-index: 2;
    font-family: ${theme.font.family.serif};
    font-weight: ${theme.font.weight.bold};
    font-style: italic;
    color: ${theme.color.dark};
    padding: 0.825rem 2rem;
    transition: 0.2s color ease;

    ${theme.mediaUp.lg`
        padding: 1rem 2rem;
        font-size: 1.25rem;
    `}

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid ${props => props.theme.color.dark};
      z-index: -2;
      background-color: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 15px 0 ${theme.color.primary};
      background: radial-gradient(
        circle,
        ${lighten(0.1, theme.color.primary)} 0%,
        ${theme.color.primary} 100%
      );
      opacity: 0;
      transition: 0.2s opacity ease;
    }

    &[aria-current='page'],
    &:hover {
      text-decoration: none;
      color: ${theme.color.white};

      &::before {
        border-bottom: 1px solid transparent;
      }

      &::after {
        opacity: 1;
      }
    }

    &:last-of-type {
      &::before {
        content: none;
      }
    }
  `
);
