import styled, { css } from 'styled-components';

export const Layout = styled.div(({ theme, menuActive }) => {
  return css`
    padding-top: ${theme.headerHeight}px;
    transform: ${menuActive
      ? `translateX(-${theme.sidebarWidth}px)`
      : `translateX(0)`};
    transition: 0.3s transform ease-in-out;

    ${theme.mediaUp.lg`
      padding-top: 0;
      transform: none;
      transition: none;
    `}
  `;
});

export const Body = styled.main`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${({ theme }) => theme.headerHeight}px);

  ${({ theme }) => theme.mediaUp.lg`
    width: calc(100% - ${theme.sidebarWidth}px);
    margin-left: auto;
    min-height: 100vh;
  `}
`;
