import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

export const Sidebar = styled.div(({ theme, menuActive }) => {
  return css`
    position: fixed;
    top: 0;
    right: 0;
    width: ${theme.sidebarWidth}px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${theme.color.white};
    border-left: 1px solid ${theme.color.dark};
    transform: ${menuActive
      ? 'translateX(0)'
      : `translateX(${theme.sidebarWidth}px)`};
    transform-origin: right;
    transition: 0.3s transform ease-in-out;

    ${theme.mediaUp.lg`
      top: ${theme.headerHeightLg}px;
      left: 0;
      right: auto;
      z-index: 1000;
      border-left: none;
      border-right: 1px solid ${theme.color.dark};
      transform: none;
      transition: none;
    `}
  `;
});

export const Link = styled(GatsbyLink)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: ${props => props.theme.sidebarWidth}px;
  padding: 1rem 2rem;
  border-bottom: 1px solid ${props => props.theme.color.dark};
  border-right: 1px solid ${props => props.theme.color.dark};

  ${props => props.theme.mediaUp.lg`
    display: block;
  `}

  &:hover {
    text-decoration: none;
  }
`;

export const Logo = styled.img`
  width: 100%;
  height: auto;
`;
