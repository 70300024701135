import React from 'react';
import { Header as StyledHeader, Link, Logo, Toggle, Icon } from './styled';
import logo from '../../images/logo.png';
import menu from '../../images/menu.svg';

function Header({ setMenuActive, menuActive }) {
  return (
    <StyledHeader menuActive={menuActive}>
      <Link to="/">
        <Logo src={logo} alt="Poetry by Andrew" />
      </Link>

      <Toggle onClick={() => setMenuActive(!menuActive)}>
        <Icon src={menu} alt="Toggle Menu" />
      </Toggle>
    </StyledHeader>
  );
}

export default Header;
