import React from 'react';
import { Sidebar as StyledSidebar, Link, Logo } from './styled';
import Nav from '../Nav';
import logo from '../../images/logo.png';

class Sidebar extends React.PureComponent {
  constructor() {
    super();

    this.sidebarRef = React.createRef();

    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  handleLinkClick(event) {
    const sidebar = this.sidebarRef.current;
    const offset = event.target.offsetTop;
    const options = {
      top: offset,
      left: 0,
      behavior: 'smooth',
    };

    sidebar.scroll(options);

    // Close menu on mobile
    this.props.setMenuActive(false);
  }

  render() {
    return (
      <>
        <Link to="/">
          <Logo src={logo} alt="Poetry by Andrew" />
        </Link>

        <StyledSidebar ref={this.sidebarRef} menuActive={this.props.menuActive}>
          <Nav handleLinkClick={this.handleLinkClick} />
        </StyledSidebar>
      </>
    );
  }
}

export default Sidebar;
