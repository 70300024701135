import React from 'react';
import { Nav as StyledNav, Link } from './styled';
import { useStaticQuery, graphql } from 'gatsby';
import { getPoemSlug } from '../../lib/utilities';

const query = graphql`
  query {
    poems: allContentfulPoem(sort: { order: DESC, fields: publishedDate }) {
      edges {
        node {
          title
          slug
          publishedDate
        }
      }
    }
  }
`;

function Nav({ handleLinkClick }) {
  const { poems: { edges: poemNodes } = {} } = useStaticQuery(query);

  const poems = poemNodes.map(({ node }) => {
    const { title, slug, publishedDate } = node;
    const timeIndex = publishedDate.indexOf('T');
    const dateWithoutTime = publishedDate.substr(0, timeIndex);
    const splitDate = dateWithoutTime.split('-');
    const date = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);

    return {
      title,
      slug: getPoemSlug(date, slug),
    };
  });

  return (
    <StyledNav>
      {poems &&
        poems.map(({ title, slug }) => {
          return (
            <Link to={slug} key={slug} onClick={handleLinkClick}>
              {title}
            </Link>
          );
        })}
    </StyledNav>
  );
}

export default Nav;
